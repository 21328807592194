@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@200;300;400;500;600;700;800&display=swap');

* {
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    max-width: 100%;
}

body, html {
    overflow-x: hidden;
    font-family: 'Outfit', sans-serif;
    background: #0C0B1E;
}

.row {
    margin-left: 0px;
}

.mt-15 {
    margin-top: 6rem;
}

.mb-15 {
    margin-bottom: 6rem;
}

input:focus,
textarea:focus {
    outline: none;
}

textarea {
    resize: none;
}

section {
    margin: 125px 0px;
}

.contact-btn a,
.waitlist-btn button,
.join-btn button,
.throw-btn button {
    padding: 16px 32px;
    border: 1px solid rgba(245, 245, 245, 0.4);
    border-radius: 4px;
    background: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #FFFFFF;
}

.contact-btn a {
    text-decoration: none;
    display: flex;
    align-items: center;

}

.contact-btn img {
    width: 24px;
    height: 24px;
    margin-left: 5px;
}

.home {
    margin-top: 70px;
    position: relative;
    z-index: 10;
    padding-bottom: 70px;
}

.home::after {
    content: '';
    position: absolute;
    width: 925px;
    height: 425px;
    left: -546px;
    top: -20px;

    background: #008DEB;
    opacity: 0.25;
    filter: blur(104px);
    z-index: -1;
}

.home::before {
    content: '';
    position: absolute;
    width: 328px;
    height: 328px;
    left: 422px;
    top: -245px;

    background: #ED9512;
    opacity: 0.72;
    filter: blur(434px);
    z-index: -1;
}

.front_shadow{
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 100%;
    /* background: #ff00002b; */
    background: linear-gradient(175deg, rgba(9, 8, 22, 0.15) 78.43%, #0C0B1E 90.05%);
    z-index: 20;
}

.home-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 70%;
}

.home-head h1,
.home-head1 h1 {
    font-weight: 700;
    font-size: 72px;
    line-height: 92px;
    color: #F5F5F5;
}

.head-paragrapgh p,
.cards-paragrapgh p,
.hood-paragrapgh p {
    font-weight: 200;
    font-size: 24px;
    line-height: 110%;
    letter-spacing: 0.02em;
    color: #F5F5F5;
    opacity: 0.6;
}

.hood-paragrapgh p {
    margin-bottom: 0;
}

.waitlist-btn button,
.join-btn button,
.throw-btn button {
    background: #E61A53;
    padding: 16px 40px;
    border: none;
}

.home-head h1 {
    display: flex;
    justify-content: flex-start;
    position: relative;
}


.animatedText {
    display: inline-block;
    position: relative;
    bottom: -71px;
    margin-left: 10px;
}

.animatedText span {
    display: inline-block;
    position: absolute;
    top: -200px;
    transform: rotateX(-90deg);
    opacity: 0;
    text-shadow: 0px 5px 5px rgba(0, 0, 0, .25);
    animation-timing-function: ease;
    color: #EB4775;
}

.animatedText span:nth-child(1) {
    animation: rollDown 7s forwards infinite;
}

.animatedText span:nth-child(2) {
    animation: rollDown2 7s forwards infinite;
}

.animatedText span:nth-child(3) {
    animation: rollDown3 7s forwards infinite;
}

@keyframes rollDown {
    0% {
        top: -100px;
        transform: rotateX(-90deg);
    }

    11% {
        top: -74px;
        transform: rotateX(0deg);
        opacity: 1;
    }

    22% {
        top: -74px;
        transform: rotateX(0deg);
        opacity: 0;
    }

    33% {
        top: 00px;
        transform: rotateX(30deg);
        opacity: 0;
    }
}

@keyframes rollDown2 {
    33% {
        top: -100px;
        transform: rotateX(-90deg);
    }

    44% {
        top: -74px;
        transform: rotateX(0deg);
        opacity: 1;
    }

    55% {
        top: -74px;
        transform: rotateX(0deg);
        opacity: 0;
    }

    66% {
        top: 00px;
        transform: rotateX(30deg);
        opacity: 0;
    }
}

@keyframes rollDown3 {
    66% {
        top: -100px;
        transform: rotateX(-90deg);
    }

    77% {
        top: -74px;
        transform: rotateX(0deg);
        opacity: 1;
    }

    88% {
        top: -74px;
        transform: rotateX(0deg);
        opacity: 0;
    }

    99% {
        top: 00px;
        transform: rotateX(30deg);
        opacity: 0;
    }
}



.join .container {
    position: relative;
    max-width: 980px;
    margin: 0 auto;
    padding: 62px 67px 77px;
    overflow: hidden;
}

.join .container::after {
    content: '';
    position: absolute;
    width: 299px;
    height: 299px;
    right: -200px;
    top: -215px;

    background: #F07598;
    opacity: 0.6;
    filter: blur(398.055px);
}

.join .container::before {
    content: '';
    position: absolute;
    width: 321.25px;
    height: 321.25px;
    left: -191px;
    top: 141px;

    background: #84FFE1;
    opacity: 0.4;
    filter: blur(197.201px);

}


.join-head h2 {
    font-size: 36px;
}

.join-confeti {
    position: absolute;
    left: 16px;
    bottom: 5px;
}

.cards-heading h2,
.hood-head h2,
.join-head h2,
.throw-head h2 {
    font-weight: 600;
    font-size: 44px;
    line-height: 120%;
    color: #F5F5F5;
}


.cards-topic {
    font-weight: 450;
    font-size: 18px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #F5F5F5;

    position: relative;
    margin-top: -50px;
}

.hood-head {
    margin-top: 30px;
}

.hood-list ul {
    padding-left: 0;
}

.hood-list ul li {
    list-style: none;
    display: flex;
    margin-bottom: 30px;
    justify-content: space-between;
    align-items: center;
}


.hood-tick {
    width: 2rem;
    display: flex;
    align-items: center;
}

.hood-paragrapgh {
    width: calc(100% - 3.5rem);
    display: flex;
    align-items: center;
}

.hood-img {
    height: 100%;
}

.hood-paragrapgh p {
    margin-bottom: 0px;
}

.join-form input,
.contactus input,
.contactus textarea,
.contactus select,
.contactus select option {
    width: 100%;
    height: 57px;
    border: 1px solid rgba(245, 245, 245, 0.12);
    border-radius: 4px;
    font-weight: 300;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #F5F5F5;
    background: none;
    padding: 15px;
    margin-bottom: 16px;
}

.contactus select option:first-child{
    color: #f7f7f7;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    border-radius: 4px;
    margin-right: 2px;
    opacity: 0.6;
    filter: invert(0.8);
  }
  
  input[type="date"]::-webkit-calendar-picker-indicator:hover {
    opacity: 1
  }


.footer-logo h5,
.copyright h5 {
    font-weight: 300;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #F5F5F5;
    opacity: 0.6;
}

.social-logo i {
    font-size: 25px;
    color: #F5F5F5;
    opacity: 0.6;
    padding: 0 16px;
}

.contactbg {
    display: block;
    position: fixed;
}

.contactus textarea {
    height: 126px;
    padding: 15px;
}

.contactus .send-btn button {
    width: 100%;
    height: 54px;
    background: #E61A53;
    border-radius: 4px;
    border: none;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #FFFFFF;
}

.hood-img img,
.throw-img img {
    position: relative;
    z-index: 1;
}

.hood-img::after {
    content: '';
    position: absolute;
    width: 299px;
    height: 299px;
    right: 0;
    top: 155px;
    background: #F07598;
    opacity: 1;
    filter: blur(398.055px);
    transform: matrix(-1, 0, 0, 1, 0, 0);
}

.hood-img::before {
    content: '';
    position: absolute;
    width: 321.25px;
    height: 321.25px;
    left: 0;
    top: 255px;
    background: #84FFE1;
    opacity: 0.5;
    filter: blur(197.201px);
    transform: matrix(-1, 0, 0, 1, 0, 0);
}

.throw-img::after {
    content: '';
    position: absolute;
    width: 299px;
    height: 299px;
    right: 530px;
    top: 170px;

    background: #F07598;
    opacity: 1;
    filter: blur(398.055px);
    transform: matrix(-1, 0, 0, 1, 0, 0);
}

.throw-img::before {
    content: '';
    position: absolute;
    width: 321.25px;
    height: 321.25px;
    left: 452px;
    top: 285px;

    background: #84FFE1;
    opacity: 0.3;
    filter: blur(197.201px);
    transform: matrix(-1, 0, 0, 1, 0, 0);

}

.features-row {
    row-gap: 45px;
}

.home-head1{
    width: 50%;
}

.contact-details--row{
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.contact-details--content a{
    color: #fff;
    text-decoration: none;
    letter-spacing: 1.1px;
}
.whatsapp-fab{
    position: fixed;
    bottom: 50px;
    right: 40px;
    background-color: #fff;
    border-radius: 100px;
    padding: 5px;
    z-index: 20;
}
.whatsapp-fab img{
    display: block;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}
.whatsapp-fab img:hover{
    transform: scale(0.9);
}

@media (max-width: 500px) {
    .navbar .container {
        padding: 0 0 !important;
    }

    .navbar-brand img {
        width: 70px;
        height: 24px;
        margin-left: 1rem;
    }

    .contact-btn {
        margin-right: 20px;
    }

    .contact-btn a {
        font-size: 10px;
        padding: 5px 15px;
        display: block;
    }

    .contact-btn img {
        width: 14px;
        height: 14px;
    }

    .home-head h1,
    .home-head1 h1 {
        font-size: 42px;
        text-align: center;
        line-height: 120%;
    }

    .head-paragrapgh p {
        font-size: 14px;
        text-align: left;
    }


    .home::after {
        content: '';
        position: absolute;
        width: 248px;
        height: 248px;
        left: -169px;
        top: 101px;

        background: #008DEB;
        opacity: 0.3;
        filter: blur(250px);
    }

    .home::before {
        content: '';
        position: absolute;
        width: 172px;
        height: 172px;
        left: 94px;
        top: -140px;

        background: #ED9512;
        opacity: 0.72;
        filter: blur(250px);
    }

    .waitlist-btn button,
    .join-btn button,
    .throw-btn button {
        padding: 12px 24px;
        font-size: 14px;
    }

    .throw-head,
    .throw-btn,
    .footer-logo h5 {
        text-align: center;
    }

    .home-img {
        margin-top: 50px;
    }

    .cards-heading h2, .hood-head h2, .throw-head h2 {
        font-size: 28px;
    }

    .head-paragrapgh p, .cards-paragrapgh p, .hood-paragrapgh p {
        font-size: 14px;
    }

    .hood-tick {
        width: 17px;
    }

    .hood-tick img {
        width: 100%;
    }

    .hood-paragrapgh {
        width: calc(100% - 27px);
    }

    /* .hood-img img {
        height: 317px;
        margin-top: 50px;
    } */

    .join {
        padding: 25px 15px !important;
        padding-right: 0 !important;
    }

    .join-head h2 {
        font-size: 20px;
    }

    .join-img img {
        width: 43px;
        height: 43px;
    }

    .join-form input, .contactus input, .contactus select {
        height: 50px;
        font-size: 12px;
    }

    .join-btn {
        justify-content: initial !important;
    }

    .throw-btn {
        margin-top: 1rem !important;
    }

    .footer-logo--premium {
        justify-content: center;
    }

    .footer .col-sm-12:nth-child(3) {
        align-items: center !important;
    }

    .footer {
        padding: 0 !important;
        padding-top: 30px !important;
    }

    .footer .col-sm-12 {
        margin-top: 25px;
    }

    .contactus .send-btn button {
        height: 38px;
    }

    .contactus textarea {
        height: 100px;
        font-size: 12px;
    }

    .party-icon {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .contactbg {
        display: none;
    }

    .contactus {
        margin-bottom: 20px;
    }

    .throw-img::after {
        content: '';
        position: absolute;
        width: 190px;
        height: 190px;
        right: 119px;
        top: 116px;

        background: #F07598;
        opacity: 1;
        filter: blur(398.055px);
        transform: matrix(-1, 0, 0, 1, 0, 0);
    }

    .throw-img::before {
        content: '';
        position: absolute;
        width: 204px;
        height: 204px;
        left: 450px;
        top: 116px;

        background: #84FFE1;
        opacity: 0.3;
        filter: blur(197.201px);
        transform: matrix(-1, 0, 0, 1, 0, 0);
        z-index: 1;

    }

    .hood-img::after {
        content: '';
        position: absolute;
        width: 163px;
        height: 163px;
        right: 0;
        top: 155px;
        background: #F07598;
        opacity: 1;
        filter: blur(398.055px);
        transform: matrix(-1, 0, 0, 1, 0, 0);
    }

    .hood-img::before {
        content: '';
        position: absolute;
        width: 175px;
        height: 175px;
        left: 0;
        top: 255px;
        background: #84FFE1;
        opacity: 0.5;
        filter: blur(197.201px);
        transform: matrix(-1, 0, 0, 1, 0, 0);
    }

    .join .container {
        padding: 30px;
    }

    .join .container::before {
        left: -118px;
        top: -101px;
        opacity: 0.24;
    }

    .join .container::after {
        /* right: -81px;
        top: 371px;
        filter: blur(150px);
        width: 135px;
        height: 135px; */
        right: -56px;
        top: 371px;
        filter: blur(65px);
        width: 135px;
        height: 135px;
    }

    .join-btn button {
        width: 100%;
    }

    .join-confeti {
        left: 62%;
        top: -9px;
        transform: scale(0.7);
    }

    .contact-section{
        margin: 0px 0px;
    }

    .home-head1{
        width: 100%;
    }
    .home-head1 h1{
        font-size: 36px;
        padding-bottom: 28px;
    }

    .whatsapp-fab{
        transform: scale(0.8);
        right: 10px;
    }
}